/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Subtitle, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Prodej ryb kosmonosy - mladá boleslav"} description={"Naše stránka prodej ryb kosmonosy-mladá boleslav nabízí informace o prodeji ryb na Prosinec 2021"}>
        <SiteHeader />

        <Column className="css-1orox7e css-jf7grh --style3 js-anim  --anim2 --left --full --parallax" anim={"2"} name={"uvod"} parallax={true} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/20312/6baab44f429f4f978e74766a577c8301_con=110_ove=000000x25__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/20312/6baab44f429f4f978e74766a577c8301_con=110_ove=000000x25__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/20312/6baab44f429f4f978e74766a577c8301_con=110_ove=000000x25__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/20312/6baab44f429f4f978e74766a577c8301_con=110_ove=000000x25__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/20312/6baab44f429f4f978e74766a577c8301_con=110_ove=000000x25__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/20312/6baab44f429f4f978e74766a577c8301_con=110_ove=000000x25__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/20312/6baab44f429f4f978e74766a577c8301_con=110_ove=000000x25__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/20312/6baab44f429f4f978e74766a577c8301_con=110_ove=000000x25__s=3000x_.jpg);
    }
  
background-position: 56% 72%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":1360}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3 pl--0" anim={"7"} animS={"3"} style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--shadow5 fs--86 lh--08" style={{"maxWidth":""}} content={"<span style=\"color: var(--white);\">ŽIVÉ RYBY KOSMONOSY 2023</span><br>"}>
              </Title>

              <Text className="text-box fs--20 w--300" style={{"maxWidth":700}} content={"<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--60 pt--60" name={"en60ovh1tw7"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box fs--30" style={{"maxWidth":900}} content={"<span style=\"color: var(--black);\"><span style=\"text-decoration-line: underline; font-weight: bold;\">Výlov</span><br></span><span style=\"font-weight: bold; color: var(--black);\">28.10.2023 p</span><span style=\"color: var(--black);\"><span style=\"font-weight: bold;\">rodej po výlovu od 10:00 - cca 12:00</span><br></span><span style=\"font-weight: bold; color: var(--black);\">na adrese: K Rybníčkům 293 06 Bradlec-Kosmonosy</span><br>"}>
              </Text>

              <Text className="text-box fs--48" style={{"maxWidth":654}} content={"<span style=\"color: var(--black); text-decoration-line: underline; font-weight: bold;\">Předvánoční prodej</span><br>"}>
              </Text>

              <Subtitle className="subtitle-box fs--30" style={{"maxWidth":1000}} content={"<span style=\"font-weight: bold;\"><span style=\"color: var(--black);\">Pátek 1.12. 2023&nbsp; &nbsp; 9:00 - 12:00</span><br></span><span style=\"font-weight: bold; color: var(--black);\">Pátek 8.12. 2023&nbsp; &nbsp;9:00 - 12:00<br>&nbsp;Pátek 15.12. 2023&nbsp; &nbsp;9:00 - 12:00<br>&nbsp; &nbsp;Sobota 16.12. 2023&nbsp; 9:00 - 12:00<br></span><span style=\"font-weight: bold; color: var(--color-custom-2);\"><br>&nbsp;na adrese:&nbsp;</span><span style=\"color: var(--black); font-weight: bold;\">Františka Opolského 51/10, 293 06 Kosmonosy<br></span><br>"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--62" content={"<span style=\"font-weight: bold; color: var(--black); text-decoration-line: underline;\">Hlavní prodej - Vánoce:</span>"}>
              </Subtitle>

              <Text className="text-box text-box--center fs--30" style={{"maxWidth":1000}} content={"<span style=\"font-weight: bold;\"><span style=\"color: var(--black);\">Čtvrtek 21.12.  9:00 -16:0<br>Pátek 22.12.  9:00 -16:00<br>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Sobota 23.12. 9:00 - do vyprodání zásob</span><br></span><span style=\"color: var(--black); font-weight: bold;\">&nbsp; &nbsp; &nbsp; na adrese: Františka Opolského 51/10, 293 06 Kosmonosy</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"esdiz6phv4"} style={{"backgroundColor":"rgba(21, 29, 37, 1)"}} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Title className="title-box title-box--left fs--62 w--500 ls--0" style={{"maxWidth":634}} content={"<span style=\"color: var(--white); text-decoration-line: underline;\">Velké množství druhu ryb dle možnosti sádek:</span>"}>
              </Title>

              <Text className="text-box text-box--left fs--30 lh--16" style={{"maxWidth":698}} content={"<span style=\"font-weight: bold; color: var(--white);\">Kapr, Lín, Amur, Tolstolobik, Štika, Sumec, Jeseter, Pstruh<br><br>-&nbsp;<span style=\"text-decoration-line: underline;\">Kuchání zajištěno</span><br>- <span style=\"text-decoration-line: underline;\">Aktuální&nbsp;Ceny ryb a kuchání vystaveny na prodejně</span></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/20312/f2af5d05e09e4e8c971f7d9356cff63c_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/20312/f2af5d05e09e4e8c971f7d9356cff63c_s=350x_.png 350w, https://cdn.swbpg.com/t/20312/f2af5d05e09e4e8c971f7d9356cff63c_s=660x_.png 660w, https://cdn.swbpg.com/t/20312/f2af5d05e09e4e8c971f7d9356cff63c_s=860x_.png 860w, https://cdn.swbpg.com/t/20312/f2af5d05e09e4e8c971f7d9356cff63c_s=1400x_.png 1400w, https://cdn.swbpg.com/t/20312/f2af5d05e09e4e8c971f7d9356cff63c_s=2000x_.png 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1orox7e --parallax pb--80 pt--80" name={"kontakt"} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/20312/6baab44f429f4f978e74766a577c8301_con=110_ove=000000x25__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/20312/6baab44f429f4f978e74766a577c8301_con=110_ove=000000x25__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/20312/6baab44f429f4f978e74766a577c8301_con=110_ove=000000x25__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/20312/6baab44f429f4f978e74766a577c8301_con=110_ove=000000x25__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/20312/6baab44f429f4f978e74766a577c8301_con=110_ove=000000x25__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/20312/6baab44f429f4f978e74766a577c8301_con=110_ove=000000x25__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/20312/6baab44f429f4f978e74766a577c8301_con=110_ove=000000x25__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/20312/6baab44f429f4f978e74766a577c8301_con=110_ove=000000x25__s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"}>
              
              <Title className="title-box fs--72" content={"<span style=\"color: white;\">Tešíme se na VÁS!</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"paticka"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--3" style={{"maxWidth":1540}} columns={"3"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--36" content={"Adresa:"}>
              </Title>

              <Text className="text-box fs--22" style={{"maxWidth":334}} content={"<span style=\"font-weight: bold; color: var(--color-custom-2);\">Františka Opolského 51/10, 293 06 Kosmonosy</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}